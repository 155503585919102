var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'DashboardHome',
          }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',[_vm._v("登記預約中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("預約日曆")])],1)],1)])]),_c('b-card',{attrs:{"title":"預約日曆"}},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"4","lg":"3"}},[_c('b-form-select',{staticClass:"mr-auto mt-2 mb-4",attrs:{"options":_vm.bookingPresetsOptions},model:{value:(_vm.bookingPresetId),callback:function ($$v) {_vm.bookingPresetId=$$v},expression:"bookingPresetId"}})],1)],1),_c('FullCalendar',{attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('span',[_vm._v(_vm._s(arg.event.extendedProps.eventContent))]),_c('div',{staticClass:"floating-tooltip",attrs:{"id":("tooltip_" + (arg.event.extendedProps.id)),"role":"tooltip"}},[_c('div',{staticClass:"popover",class:("bs-popover-" + _vm.placement)},[_c('h3',{staticClass:"popover-header"},[_vm._v(_vm._s(arg.event.extendedProps.eventContent))]),_c('div',{staticClass:"popover-body"},[_vm._l((_vm.bookingPresetFields),function(field){return _c('div',{key:field.key},[_c('span',[_vm._v(_vm._s(field.label))]),_c('span',[_vm._v("：")]),(arg.event.extendedProps)?[_c('span',[_vm._v(_vm._s(_vm.isObject(arg.event.extendedProps[field.key]) ? arg.event.extendedProps[field.key].text : arg.event.extendedProps[field.key]))])]:_vm._e()],2)}),_c('router-link',{attrs:{"to":{
                  name: 'BookingCenterBookingEdit',
                  params: {
                    id: _vm.bookingPresetId,
                    booking_id: arg.event.extendedProps.id
                  }
                }}},[_c('span',{on:{"click":function () { return _vm.closeTooltip(); }}},[_vm._v("查看明細")])])],2),_c('span',{staticClass:"arrow",attrs:{"id":("arrow_" + (arg.event.extendedProps.id))}})])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }